.color-picker {
  display       : flex;
  flex-direction: column;
  align-items   : center;
  text-align    : center;

  .swatch {
    width: 36px;
    height: 14px;
    border-radius: 2px;
  }
}

// .color-picker input {
//   display       : block;
//   box-sizing    : border-box;
//   width         : 90px;
//   margin        : 20px 55px 0;
//   padding       : 6px;
//   border        : 1px solid #ddd;
//   border-radius : 4px;
//   background    : #eee;
//   outline       : none;
//   font          : inherit;
//   text-transform: uppercase;
//   text-align    : center;
// }

// .color-picker input:focus {
//   border-color: #4298ef;
// }